import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';

import {
  LinkDirective,
  SummaryListComponent,
  SummaryListRowActionsDirective,
  SummaryListRowDirective,
  SummaryListRowKeyDirective,
  SummaryListRowValueDirective,
} from '@netz/govuk-components';

import { SummaryDownloadFilesComponent } from '../summary-download-files/summary-download-files.component';
import { SummaryQueryParamsPipe } from './queryParams.pipe';
import { SummaryData } from './type';

@Component({
  selector: 'cca-summary',
  templateUrl: './summary.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    SummaryListComponent,
    SummaryListRowDirective,
    SummaryListRowKeyDirective,
    SummaryListRowValueDirective,
    SummaryListRowActionsDirective,
    RouterLink,
    LinkDirective,
    SummaryQueryParamsPipe,
    SummaryDownloadFilesComponent,
  ],
})
export class SummaryComponent {
  data = input.required<Readonly<SummaryData>>();
}
